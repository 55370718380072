<div class="signout-prompt-container" [ngClass]="isMobile ? 'drawer' : ''">
  <h2 class="text-dark mt-32" id="leave-or-stay-title">
    {{ titleKey | translate }}
  </h2>
  <span class="mat-body-2 mt-8 mb-32 text-dark-charcoal" id="leave-or-stay-subtitle">
    {{ subtitleKey | translate }}
  </span>
  <div [ngClass]="!isMobile ? 'btn-row flex-end' : 'btn-col'">
    <button mat-flat-button color="accent" (click)="close(true)" id="leave-button">
      {{ 'CHECKOUT.LEAVE_OR_STAY.LEAVE_BUTTON' | translate }}
    </button>
    <button mat-flat-button color="primary" (click)="close()" id="stay-button">
      {{ 'CHECKOUT.LEAVE_OR_STAY.STAY_BUTTON' | translate }}
    </button>
  </div>
</div>
